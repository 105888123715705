import { DateTime, Interval } from "luxon"

function timer({wrapper}){

    const timerWrapper = document.querySelector(wrapper)
    // конечная дата, например 1 июля 2021
    //const deadline = new Date('2024-01-26');
    //const deadline = new Date(2023, 11, 9, 12, 35);
    const timerStart = timerWrapper.querySelector('#time-start').value
    const deadline = new Date(timerStart);

    let dt = DateTime.now().setZone('Europe/London');
    let now = new Date(dt.year, dt.month-1, dt.day, dt.hour, dt.minute, dt.second);

    // id таймера
    let timerId = null;
    // склонение числительных
    function declensionNum(num, words) {
        return words[(num % 100 > 4 && num % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(num % 10 < 5) ? num % 10 : 5]];
    }
    // вычисляем разницу дат и устанавливаем оставшееся времени в качестве содержимого элементов
    function countdownTimer() {
        dt = DateTime.now().setZone('Europe/London')
        now = new Date(dt.year, dt.month-1, dt.day, dt.hour, dt.minute, dt.second)
        const diff = deadline - now;

        if (diff <= 0) {
            clearInterval(timerId);
            window.location.reload();
        }
        const days = diff > 0 ? Math.floor(diff / 1000 / 60 / 60 / 24) : 0;
        const hours = diff > 0 ? Math.floor(diff / 1000 / 60 / 60) % 24 : 0;
        const minutes = diff > 0 ? Math.floor(diff / 1000 / 60) % 60 : 0;
        const seconds = diff > 0 ? Math.floor(diff / 1000) % 60 : 0;

        $days.textContent = days < 10 ? '0' + days : days;
        $hours.textContent = hours < 10 ? '0' + hours : hours;
        $minutes.textContent = minutes < 10 ? '0' + minutes : minutes;
        $seconds.textContent = seconds < 10 ? '0' + seconds : seconds;


        $days.dataset.title = declensionNum(days, ['days', 'days', 'days']);
        $hours.dataset.title = declensionNum(hours, ['hours', 'hours', 'hours']);
        $minutes.dataset.title = declensionNum(minutes, ['minutes', 'minutes', 'minutes']);
        $seconds.dataset.title = declensionNum(seconds, ['seconds', 'seconds', 'seconds']);


        /*$days.dataset.title = declensionNum(days, ['день', 'дня', 'дней']);
        $hours.dataset.title = declensionNum(hours, ['час', 'часа', 'часов']);
        $minutes.dataset.title = declensionNum(minutes, ['минута', 'минуты', 'минут']);
        $seconds.dataset.title = declensionNum(seconds, ['секунда', 'секунды', 'секунд']);*/
    }
    // получаем элементы, содержащие компоненты даты
    const $days = timerWrapper.querySelector('.timer__days');
    const $hours = timerWrapper.querySelector('.timer__hours');
    const $minutes = timerWrapper.querySelector('.timer__minutes');
    const $seconds = timerWrapper.querySelector('.timer__seconds');
    // вызываем функцию countdownTimer

    const diffFirst = deadline - now;

    if (diffFirst > 0) {
        countdownTimer();
        // вызываем функцию countdownTimer каждую секунду
        timerId = setInterval(countdownTimer, 1000);
    }


}

export default timer;
